import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { ModalHeader } from '../../../shared/ModalHeader'
import { API_URL } from '../../../../services/api';
import { Container } from './styles';
import moment from 'moment';

export function CancelScheduleParkingModal({ isOpen, onRequestClose, vehicleSchedule }) {
  const [loading, setLoading] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!window.confirm('Confirma o cancelamento ?')) return

    setLoading(true)

    const res = await fetch(`${API_URL}/parkings/schedule/${vehicleSchedule?.licensePlate}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      }
    });

    const response = await res.json();
    setLoading(false)
    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container onSubmit={handleSubmit}>
        <ModalHeader
          title={'Cancelar Agendamento: ' + vehicleSchedule?.licensePlate}
          handleClose={onRequestClose}
        />

        <p><span>Regra:</span> {vehicleSchedule?.ruleName}</p>

        <p><span>Inicio:</span> {moment(vehicleSchedule?.startDate).format("DD/MM/YYYY HH:mm")}</p>

        <hr className='hr'></hr>

        {loading ? (
          <button className="secondButton" disabled>
            CANCELANDO ...
          </button>
        ) : (
          <button className="secondButton">
            CANCELAR AGENDAMENTO
          </button>
        )}
      </Container>
    </Modal>
  );
}
