import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Container, InfoContainer } from './styles';
import { toCurrency } from '../../../../../../helpers/toCurrency';
import { ModalHeader } from '../../../../../shared/ModalHeader';
import { ApiZad } from '../../../../../../services/api-zad';

export function DetailHistoricUseModal({ isOpen, onRequestClose, detailsData }) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const emailUser = sessionStorage.getItem('email');
  const profile = sessionStorage.getItem('profile')

  const handleProof = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { res, response } = await ApiZad.post('/proofs/email', { email, authenticateCode: detailsData.authenticateCode })

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
    }

    setLoading(false);
    setEmail('');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay z-index-5"
      className="react-modal-content"
    >
      <Container onSubmit={handleProof}>
        <ModalHeader title='Comprovante de Estacionamento' handleClose={onRequestClose} />

        <p>
          Local do estacionamento
          <br />
          <span>{detailsData.addressName}</span>
        </p>

        <InfoContainer>
          <p>
            Data e hora
            <br />
            <span>{detailsData.startDate}</span>
          </p>
          <p>
            Placa do veículo
            <br />
            <span>{detailsData.licensePlate}</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Quantidade de Cartões
            <br />
            <span>{detailsData.quantity}</span>
          </p>
          <p>
            Tempo estacionado
            <br />
            <span>{detailsData.time / 60} hora(s)</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Valor em reais
            <br />
            <span>
              { toCurrency(detailsData.value) } { profile === 'pdv' && ` (${detailsData.payment})` }
            </span>
          </p>
          <p>
            Código de autenticação
            <br />
            <span>{detailsData.authenticateCode}</span>
          </p>
        </InfoContainer>

        <h3>Informar e-mail para envio do comprovante:</h3>

        <input
          placeholder="E-mail do cliente"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
          required
        />

        {loading ? (
          <button type="submit" disabled>
            Enviando
          </button>
        ) : (
          <button type="submit">
            Enviar para o e-mail informado
          </button>
        )}

        <h4>ou</h4>

        {loading ? (
          <button className="secondButton" disabled>
            Enviando
          </button>
        ) : (
          <button
            className="secondButton"
            onClick={() => {
              setEmail(emailUser);
            }}
          >
            Enviar para meu e-mail
          </button>
        )}
      </Container>
    </Modal>
  );
}
