import { useState, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { maskPhone, unMask } from "../../../utils/masks";
import MaskedInput from "react-text-mask";
import { API_URL } from "../../../services/api";
import { Container, InputGrid } from "./styles";
import { PasswordInput } from "../../shared/PasswordInput";
import { UserDeleteModal } from "../../AppPdv/Modals/UserDeleteModal";
import { ModalHeader } from "../../shared/ModalHeader";

export function UserModal({ isOpen, onRequestClose, userData }) {
  const [modal, setModal] = useState({})
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    setName(userData.name);
    setBirthday(userData.birthDay);
    setPhone(userData.phone);
    setEmail(userData.email);
    setPassword("");
    setNewPassword("");
  }, [userData]);

  const today = new Date();
  const year = today.getFullYear();
  const minYear = +year - 125;
  const maxYear = +year - 18;

  const handleEditUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const birthdayYear = birthday.substring(0, 4);

    if (
      birthdayYear < minYear.toString() ||
      birthdayYear > maxYear.toString()
    ) {
      toast.error("Data de nascimento inválida!");
      setLoading(false);
      return;
    }

    const body = {
      name,
      birthday,
      phone: `+55${unMask(phone)}`,
      email
    }

    if (password || newPassword !== "") {
      body.password = password
      body.newPassword = newPassword
    }

    const res = await fetch(`${API_URL}/users`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setLoading(false);
      setTimeout(onRequestClose, 3000);
    }

    setLoading(false);
  }

  const closeModal = () => setModal({})

  const openUserDeleteModal = () => {
    setModal({
      show: <UserDeleteModal
        isOpen={true}
        onRequestClose={closeModal}
      />
    })
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Container onSubmit={handleEditUser}>
          <ModalHeader title='Editar minha conta' handleClose={onRequestClose} />

          <input
            placeholder="Nome ou Razão Social"
            type="text"
            maxLength="60"
            value={name}
            autoFocus
            onChange={(event) => setName(event.target.value)}
          />

          <div className='px-2' style={{ display:'grid', gridTemplateColumns:'1fr 1fr' }}>
            <div>
              <p>Tipo de usuário</p>
              {userData.profile === 'user' && <strong>Aplicativo</strong>}
              {userData.profile === 'pdv' && <strong>Ponto de Venda</strong>}
              {userData.profile === 'support' && <strong>Suporte</strong>}
              {userData.profile === 'adm' && <strong>Administrador</strong>}
            </div>
            <div>
              {userData.profile === 'user' && <><p>CPF / CNPJ</p><strong>{userData.cpf_cnpj}</strong></>}
              {userData.profile === 'pdv' && <><p>Código PDV</p><strong>{userData.pdvCode}</strong></>}
              {userData.profile === 'support' && <><p>Matrícula</p><strong>{userData.cpf_cnpj}</strong></>}
              {userData.profile === 'adm' && <><p>Matrícula</p><strong>{userData.cpf_cnpj}</strong></>}
            </div>
          </div>

          <InputGrid>
            <input
              placeholder="Data de nascimento"
              type="date"
              min={`${minYear}-01-01`}
              max={`${maxYear}-12-31`}
              value={birthday}
              onChange={(event) => setBirthday(event.target.value)}
            />

            <MaskedInput
              mask={maskPhone}
              placeholder="Telefone"
              guide={false}
              type="tel"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </InputGrid>
          <input
            placeholder="E-mail"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <InputGrid>
            {newPassword ? (
              <PasswordInput
                placeholder="Senha Atual"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                minLength={6}
                required
              />
            ) : (
              <PasswordInput
                placeholder="Senha Atual"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                minLength={6}
              />
            )}

            {password ? (
              <PasswordInput
                placeholder="Nova Senha"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                minLength={6}
                required
              />
            ) : (
              <PasswordInput
                placeholder="Nova Senha"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                minLength={6}
              />
            )}
          </InputGrid>

          <hr className='hr'></hr>

          {loading ? (
            <button type="submit" className='btn' disabled>
              Salvando
            </button>
          ) : (
            <button type="submit" className='btn'>Salvar</button>
          )}

          {userData.profile === 'user' &&
            <button type="button" className='btn danger' onClick={openUserDeleteModal}>
              Excluir Conta
            </button>
          }
        </Container>
      </Modal>
    </>
  );
}
