import { useState } from 'react'
import Modal from 'react-modal'
import { ModalHeader } from '../../../shared/ModalHeader'
import { Icon, Tab } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faMapMarkerAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import './styles.css'
import { useFetch } from '../../../../hooks/useFetch'
import { API_URL } from '../../../../services/api'
import { DetailHistoricUseModal } from './historicUse/detail'
import { DetailHistoricBuyModal } from './historicBuy/detail'
import { HistoricBuy } from './historicBuy'
import { HistoricUse } from './historicUse'
import { LoadingModal } from '../../../Modals/LoadingModal'
import { FilterHistoricModal } from './filter'

const filterConfig = { days: 30 }

export const HistoricModal = ({ isOpen, onRequestClose }) => {
  const [modal, setModal] = useState({})
  const [showHistoric, setShowHistoric] = useState('buy')
  const [filterHistoricBuy, setFilterHistoricBuy] = useState(filterConfig)
  const [filterHistoricUse, setFilterHistoricUse] = useState(filterConfig)

  const { 
    response: responseHistoricUse, 
    load: loadHistoricUse,
    // refresh: refreshHistoricUse
  } = useFetch(`${API_URL}/historic-parkings?${new URLSearchParams(filterHistoricUse)}`)

  const historicUse = responseHistoricUse?.data || []

  const { 
    response: responseHistoricBuy, 
    load: loadHistoricBuy,
    // refresh: refreshHistoricBuy
  } = useFetch(`${API_URL}/historic-payments?${new URLSearchParams(filterHistoricBuy)}`)

  const historicBuy = responseHistoricBuy?.data || []

  // console.log('HistoricModal:', 'Rendered')

  const closeModal = () => setModal({})

  const openDetailHistoricBuyModal = (historicSelected) => {
    setModal({
      show: <DetailHistoricBuyModal
        isOpen={true}
        onRequestClose={closeModal}
        detailsData={historicSelected}
      />
    })
  }

  const openDetailHistoricUseModal = (historicSelected) => {
    setModal({
      show: <DetailHistoricUseModal
        isOpen={true}
        onRequestClose={closeModal}
        detailsData={historicSelected}
      />
    })
  }

  const openFilterHistoricModal = (filter, setFilter) => {
    setModal({
      show: <FilterHistoricModal
        isOpen={true}
        onRequestClose={closeModal}
        filter={filter}
        setFilter={setFilter}
      />
    })
  }

  if (loadHistoricBuy && loadHistoricUse) {
    return <LoadingModal />
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ModalHeader title='Históricos' handleClose={onRequestClose}>
          <div
            className='is-flex p-2 is-align-items-center ml-3 is-clickable'
            style={{ borderRadius:'100%', backgroundColor:'var(--blue)' }}
            onClick={() => {
              showHistoric === 'buy'
                ? openFilterHistoricModal(filterHistoricBuy, setFilterHistoricBuy)
                : openFilterHistoricModal(filterHistoricUse, setFilterHistoricUse)
            }}
          >
            <FontAwesomeIcon icon={faFilter} style={{ color:'var(--shape)', fontSize:'0.9rem' }} />
          </div>
        </ModalHeader>

        <Tab.Group kind="toggle-rounded" align='centered'>
          <Tab
            active={showHistoric === 'buy'}
            onClick={() => setShowHistoric('buy')}
          >
            <Icon size="small">
              <FontAwesomeIcon icon={faShoppingCart} />
            </Icon>
            <span>Compras</span>
          </Tab>
          <Tab
            active={showHistoric === 'use'}
            onClick={() => setShowHistoric('use')}
          >
            <Icon size="small">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </Icon>
            <span>Utilizações</span>
          </Tab>
        </Tab.Group>

        <div>
          { 
            showHistoric === 'buy' && (
              <>
                <div className='mb-2'>
                  Periodo: {historicBuy?.totalPayment?.periodTime}
                </div>
                {
                  historicBuy?.payments?.map((payment, index) =>
                    <HistoricBuy 
                      key={'payment_' + index}
                      payment={payment}
                      onClick={() => openDetailHistoricBuyModal(payment)}
                    />
                  )
                }
              </>
            )
          }

          { showHistoric === 'use' && (
              <>
                <div className='mb-2'>
                  Periodo: {historicUse?.totalParkings?.periodTime}
                </div>
                {
                  historicUse?.indexParking?.map((parking, index) => (
                    <HistoricUse
                      key={'parking_' + index}
                      parking={parking}
                      onClick={() => openDetailHistoricUseModal(parking)}
                    />
                  ))
                }
              </>
            )
          }
        </div>
      </Modal>
    </>
  )
}
