import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { Images } from '../../../../utils/images';
import { maskCardCvv, maskPlate } from '../../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../../services/api';
import {
  Container,
  RadioContainerPayment,
  RadioBox,
  ValueContainer
} from './styles';
import { SearchParkingPlace } from '../../SearchParkingPlace';
import { useEffect } from 'react';
import { ParkingService } from '../../../../services/api-zad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { env } from '../../../../env';
import { toCurrency } from '../../../../helpers/toCurrency';
import { useFetch } from '../../../../hooks/useFetch';
import { Card } from '../CardModal/card';
import { LoadingModal } from '../../../Modals/LoadingModal';
import { ModalHeader } from '../../../shared/ModalHeader';
import { CardForm } from '../CardModal/form';
import { DropdownCreditCard } from '../../../shared/DropdownCreditCard';

export function ParkingModal({ isOpen, onRequestClose, placa }) {
  const profile = sessionStorage.getItem('profile')
  const discount = sessionStorage.getItem('discount');

  // const [allowLocation, setAllowLocation] = useState(false)
  const [addressName, setAddressName] = useState('');
  const [ruleName, setRuleName] = useState('');
  const [rules, setRules] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [amountCad, setAmountCad] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('pix');
  const [startDate, setStartDate] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [loading, setLoading] = useState('');
  const [cardSelected, setCardSelected] = useState({})
  const [modal, setModal] = useState({})

  const ruleSelected = rules.find(rule => rule.name === ruleName)

  const { 
    response: cardsResponse,
    refresh: cardsRefresh
  } = useFetch(`${API_URL}/cards`)

  const cards = cardsResponse?.data?.cards || []
  // const lastCard = cards.reverse()[0]

  const { 
    response: cadResponse,
    refresh: cadRefresh,
    load: cadLoad
  } = useFetch(`${API_URL}/transactions`)

  const cadQuantity = cadResponse?.data?.cads || 0

  const { 
    response: responseEducationalPeriod 
  } = useFetch(`${API_URL}/educational-period`)

  const educationalPeriod = responseEducationalPeriod?.data || {}

  useEffect(() => {
    cleanForm()
    fetchRules()
    cardsRefresh()
    cadRefresh()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchRules = async () => {
    try {
      const response = await ParkingService.regionRules();
      setRules(response.data.data);
      setRuleName(response.data.data[0].name)
    } catch(e) {
      console.log(e, ' - ', e.message);
    }
  }

  useEffect(() => {
    if (profile === 'user') setLicensePlate( placa || '')
  }, [profile, placa])

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(setLatLng, showError)
  //   }
  // }, [isOpen])

  // const setLatLng = (position) => {
  //   setAllowLocation(true)
  //   setLatitude(position.coords.latitude)
  //   setLongitude(position.coords.longitude)
  // }

  // const showError = (error) => {
  //   setAllowLocation(false)
  //   console.log('ParkingModal: ', error)
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!addressName) { toast.warn('Informe o local do estacionamento.'); return; }

    if (!latitude) { toast.warn('Latitude: não pode ser nulo.'); return; }

    if (!longitude) { toast.warn('Longitude: não pode ser nulo.'); return; }

    if (!educationalPeriod.valid) {
      if (cadQuantity <= 0 && (!cardSelected.pagarmeCardId || !cardCvv) ) {
        toast.warn('Você não possui saldo. Selecione um cartão de crédito'); return;
      }
    }

    setLoading(true);

    cadRefresh();

    let body = {
      addressName,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      licensePlate,
      ruleName,
      amountCad,
      imei: env.IMEI_DEFAULT,
      firebaseToken: '',
      paymentMethod
    }

    let route = `${API_URL}/parkings`

    // quando houver agendamento
    if (startDate) {
      route = `${API_URL}/parkings/schedule`
      body = {
        address: addressName,
        lat: latitude.toString(),
        lng: longitude.toString(),
        ruleName,
        licensePlate,
        startDate,
        amountCad,
        firebaseToken: '',
        paymentMethod,
        cardId: cardSelected.pagarmeCardId || '',
        cardCvv
      }
      // quando for Pagar e Estacionar
    } else if (cadQuantity <= 0) {
      route = `${API_URL}/pay-and-park`
      body = {
        addressName,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        licensePlate,
        ruleName,
        amountCad,
        firebaseToken: '',
        cardId: cardSelected.pagarmeCardId || '',
        cardCvv
      }
    }

    const res = await fetch(route, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      cleanForm()
      setTimeout(() => {
        // onRequestClose()
        window.location.reload()
      }, 3000);
    }

    setLoading(false);
  };

  const cleanForm = () => {
    setAddressName('')
    // setRuleName('')
    setLatitude('')
    setLongitude('')
    setStartDate('')
    setLicensePlate(placa||'')
    // setAmountCad(1)
    // setPaymentMethod('pix')
    setCardSelected({})
  }

  const RadioBoxPayment = ({ label, method, image }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => setPaymentMethod(method)}
        isActive={paymentMethod === method}
        activeColor="green"
      >
        <img src={image} alt={label} />
        <span>{label}</span>
      </RadioBox>
    )
  }

  const OptionsForSelect = ({ quantity }) => {
    let options = []

    for (let i = 2; i <= quantity; i++) {
      options.push(<option key={i} value={i}>{i} Cartões</option>)
    }

    return (
      <>{options}</>
    )
  }

  // if (!allowLocation) {
  //   return (
  //     <Modal
  //       isOpen={isOpen}
  //       onRequestClose={() => {
  //         cleanForm()
  //         onRequestClose()
  //       }}
  //       overlayClassName="react-modal-overlay"
  //       className="react-modal-content"
  //     >
  //       <Container onSubmit={handleSubmit}>
  //         <ModalHeader
  //           title={'Estacionar ' + licensePlate}
  //           handleClose={() => {
  //             cleanForm()
  //             onRequestClose()
  //           }}
  //         />

  //         <h3>Permita o uso da sua localização para realizar o estacionamento.</h3>
  //       </Container>
  //     </Modal>
  //   )
  // }

  const selectCard = (card, cardCvv) => {
    setCardSelected(card)
    setCardCvv(cardCvv)
  }

  const closeModal = () => setModal({})

  const openCardForm = () => {
    setModal({
      show: <CardForm
        isOpen={true}
        onRequestClose={closeModal}
        cardsRefresh={cardsRefresh}
        selectCard={selectCard}
      />
    })
  }

  if (cadLoad) {
    return <LoadingModal />
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          cleanForm()
          onRequestClose()
        }}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Container onSubmit={handleSubmit}>
          <ModalHeader
            title={'Estacionar ' + licensePlate}
            handleClose={() => {
              cleanForm()
              onRequestClose()
            }}
          />

          <MaskedInput
            mask={maskPlate}
            placeholder="Placa"
            guide={false}
            type="text"
            value={licensePlate.toUpperCase()}
            onChange={(event) => setLicensePlate(event.target.value)}
            required
          />

          <SearchParkingPlace 
            setAddress={setAddressName}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            geocode={!latitude}
          />

          {/* <input
            placeholder="Local do estacionamento"
            value={addressName}
            disabled
          /> */}

          <h3 className='mb-0 ml-1'>
            <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />
            {addressName||'local do estacionamento'}
          </h3>
          {/* <InputsGrid>
            <input
              placeholder="Latitude"
              value={latitude}
              disabled
            />
            <input
              placeholder="Longitude"
              value={longitude}
              disabled
            />
          </InputsGrid> */}

          <select
            type="text"
            value={ruleName}
            onChange={(event) => {
              setRuleName(event.target.value)
              // setAmountCad('')
            }}
            required
          >
            {/* <option value="" disabled defaultValue hidden>
              Regra do local
            </option> */}
            {
              rules.map(
                (rule, index) => (<option key={index} value={rule.name}>{rule.name}</option>)
              )
            }
          </select>

          <select
            type="text"
            value={amountCad}
            onChange={(event) => setAmountCad(event.target.value)}
            required
          >
            {/* <option value="" disabled defaultValue hidden>
              Quantidade de Cartões
            </option> */}
            <option key={1} value="1">1 Cartão</option>
            { ruleSelected?.maxCad && <OptionsForSelect quantity={ruleSelected?.maxCad} /> }
          </select>

          {
            profile === 'pdv' && (
              <>
                <ValueContainer>
                  <h3>
                    Valor à receber: <span>{ toCurrency(+amountCad * env.CAD_VALUE) }</span>
                  </h3>
                </ValueContainer>

                <RadioContainerPayment>
                  <RadioBoxPayment label='Dinheiro' method='money' image={Images.moneyImg} />
                  <RadioBoxPayment label='Pix' method='pix' image={Images.pixImg} />
                  <RadioBoxPayment label='Débito' method='debit_card' image={Images.creditCardImg} />
                  <RadioBoxPayment label='Crédito' method='credit_card' image={Images.creditCardImg} />
                </RadioContainerPayment>
              </>
            )
          }

          {
            !educationalPeriod.valid && cadQuantity <= 0 &&
              <>
                <hr className='hr'></hr>

                <DropdownCreditCard
                  cardsData={cards}
                  openCardForm={openCardForm}
                  setCardSelected={setCardSelected}
                />

                {
                  Object.keys(cardSelected).length > 0 && 
                    <div className='columns is-mobile my-1'>
                      <div className='column pb-1'>
                        <Card card={cardSelected} />
                      </div>
                      <div className='column is-one-quarter pb-0 m-auto'>
                        <MaskedInput
                          mask={maskCardCvv}
                          placeholder="CVV"
                          guide={false}
                          type="text"
                          autoFocus
                          value={cardCvv}
                          onChange={(event) => setCardCvv(event.target.value)}
                          required
                        />
                      </div>
                    </div>
                }

                {
                  profile === 'pdv' &&
                    <ValueContainer>
                      <>
                        <h3>
                          Desconto:{' '}
                          <span style={{ color:'var(--green)' }}>
                            { toCurrency(+amountCad * env.CAD_VALUE * (discount / 100)) }
                          </span>
                        </h3>
                        <h3>
                          Valor à pagar:{' '}
                          <span>
                            { toCurrency(+amountCad * env.CAD_VALUE - amountCad * env.CAD_VALUE * (discount / 100)) }
                          </span>
                        </h3>
                      </>
                    </ValueContainer>
                }
              </>
          }
          
          <hr className='hr'></hr>

          {
            // cadQuantity < amountCad &&
              <>
                <h3 className='mb-3'>
                  <FontAwesomeIcon icon={faClock} className='mr-2' />
                  Para AGENDAR informe abaixo a Data e Hora.
                </h3>

                <input
                  type="datetime-local"
                  placeholder="Data e Hora"
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </>
          }

          <hr className='hr'></hr>

          {
            startDate
              ? loading
                ? <button className='schedule' type="submit" disabled>
                    <FontAwesomeIcon icon={faClock} className='mr-2' />
                    Agendando
                  </button>
                : <button className='schedule' type="submit">
                    <FontAwesomeIcon icon={faClock} className='mr-2' />
                    Agendar
                  </button>

              : loading
                ? <button className='park' type="submit" disabled>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />
                    Estacionando
                  </button>
                : <button className='park' type="submit">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />
                    Estacionar
                  </button>
          }
        </Container>
      </Modal>
    </>
  );
}
