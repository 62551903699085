import { useState } from "react";
import { Images } from '../../../utils/images';
import { Container, Content } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useFetch } from "../../../hooks/useFetch";
import { API_URL } from "../../../services/api";
import { env } from "../../../env";
import { toCurrency } from "../../../helpers/toCurrency";
import { CadsModal } from "../Modals/CadsModal";
import { ParkingModal } from "../Modals/ParkingModal";
// import { ScheduleParkingModal } from "../Modals/ScheduleParkingModal";

export function Header() {
  const [modal, setModal] = useState({})

  // const { 
  //   response: responseTimeRuleToday 
  // } = useFetch(`${API_URL}/time-rule/today`)

  // const timeRuleToday = responseTimeRuleToday?.data || {}

  const { 
    response: cadResponse,
    refresh: cadRefresh
  } = useFetch(`${API_URL}/transactions`)

  const cadQuantity = cadResponse?.data?.cads || 0

  const { 
    response: responseEducationalPeriod 
  } = useFetch(`${API_URL}/educational-period`)

  const educationalPeriod = responseEducationalPeriod?.data || {}

  const profile = sessionStorage.getItem('profile')

  const closeModal = (callback) => {
    if (callback) callback()
    setModal({})
  }

  const openCadsModal = () => {
    setModal({
      show: <CadsModal
        isOpen={true}
        onRequestClose={() => closeModal(cadRefresh)}
        options={{educationalPeriod}}
      />
    })
  }

  const openParkingModal = () => {
    setModal({
      show: <ParkingModal
        isOpen={true}
        onRequestClose={closeModal}
      />
    })
  }

  // const openScheduleParkingModal = () => {
  //   setModal({
  //     show: <ScheduleParkingModal
  //       isOpen={true}
  //       onRequestClose={closeModal}
  //     />
  //   })
  // }

  return (
    <>
      { modal.show }

      <Container>
        <Content>
          <div className="header-logo">
            <img className="logo" src={Images.logoZad02} alt="Transitar Consultoria" />

            <div className="saldo">
              <div>
                <span className='is-size-4 has-text-weight-medium'> { cadQuantity } </span> <span className='is-size-5'>{cadQuantity === 1 ? 'Cartão' : 'Cartões'}</span>
              </div>

              <div className='is-size-5'>
                ( { toCurrency(cadQuantity * env.CAD_VALUE) } )
              </div>
            </div>
          </div>

          <div className="header-actions">
            {
              profile === 'user' && 
              <button className="" onClick={openCadsModal}>
                <FontAwesomeIcon icon={faShoppingCart} className="mr-2" /> COMPRAR
              </button>
            }
            {
              profile === 'pdv' && 
                <>
                  <button className="buy" onClick={openCadsModal}>
                    <FontAwesomeIcon icon={faShoppingCart} className="mr-2" /> COMPRAR
                  </button>
                  <button className="park" onClick={openParkingModal}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> ESTACIONAR
                  </button>
                  {/* {
                    timeRuleToday?.validTimeForParking === false 
                      ? <button className="park" onClick={openScheduleParkingModal}>
                          <FontAwesomeIcon icon={faClock} className="mr-2" /> AGENDAR
                        </button>
                      : <button className="park" onClick={openParkingModal}>
                          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> ESTACIONAR
                        </button>
                  } */}
                </>
            }
          </div>
        </Content>
      </Container>
    </>
  )
}
